





























































































































































































































































































































import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment, { Moment } from "moment";
import { arService } from "@service/ar.service";
import { IArDataList, IArDataResponse } from "@interface/account-receivables.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { debounceProcess } from "@helper/debounce";
import { Component, Ref, Vue } from "vue-property-decorator";
import { ResponsePagination } from "@/models/interface/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { downloadFile } from "@/helpers/file-reader";
import { ResponseBranchWarehouse } from "@interface/warehouse.interface";
import { IOption } from "@interface/common.interface";
import { DataResponseCompanyBank } from "@/models/interface/master.interface";
import { trimSpaceToUnderscore } from "@/helpers/common";

@Component({
  mixins: [
    MNotificationVue,
  ],
  components: {
    ReceiptType: () => import(/*webpackPrefetch: true*/"./ReceiptTypeSelect.vue"),
    CSelectBranch: () => import(/*webpackPrefetch: true */"@/components/shared/select-branch/CSelectBranch.vue"),
    CSelectCustomer: () => import(/*webpackPrefetch: true */"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true */"@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectCompanyBank: () => import(/*webpackPrefetch: true */"@/components/shared/select-company-bank/CSelectCompanyBank.vue"),
    CSelectARChequeNumber: () => import(/*webpackPrefetch: true */"@/components/shared/select-ar-cheque-number/CSelectARChequeNumber.vue"),
  },
})
export default class ReceiptAr extends Vue {
  @Ref("searchForm") searchForm;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = {
    branch: undefined as string | undefined,
    arReceiptNumber: undefined as string | undefined,
    customerName: undefined as string | undefined,
    receiptDate: [] as Moment[],
    currency: "",
    receiptType: undefined as string | undefined,
    bankName: undefined as string | undefined,
    chequeNumber: undefined as string | undefined,
    branchName: "",
    customerFullName: "",
    bankCompanyName: "",
  };
  arReceiptNumberOption = {
    data: [] as IArDataResponse[],
    search: "",
    fetching: true,
    initialData: [] as IArDataResponse[],
  };
  dataCustomerName: ContactData[] = []
  dtSource: IArDataResponse[] = [];
  query: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    sorts: "createdDate:desc",
  };
  loadingIndicator = {
    data: false,
    download: false
  };
  dtReceiptAr: IArDataList = {
    data: [] as IArDataResponse[],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  }

  created(): void {
    this.getArReceiptNumber = debounceProcess(this.getArReceiptNumber, 300);

    this.getArReceiptNumber(true);
    this.getListArReceipt();
  }

  getListArReceipt$(query: RequestQueryParamsModel): Promise<IArDataList> {
    return arService.getListArReceipt(query);
  }

  get hasPrivilegeReceiptArCreate(): boolean {
    return this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "invoice-receipt" && x.privilege.create && x.privilege.update);
  }

  goToView(id: string): void {
    this.$router.push({ name: "accountreceivables.ar.receipt.view", params: { id } });
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.query.limit = response.size;
    this.query.page = 0;
    this.getListArReceipt();
  }
  
  responseCurrentPageChange(response: ResponsePagination): void {
    this.query.page = response.page - 1;
    this.getListArReceipt();
  }

  handleDownload(): void {
    this.loadingIndicator.download = true;
    arService.downloadListArReceipt(this.query)
      .then((response) => {
        if (response) {
          downloadFile(new Blob([response]), "report_invoice_receipt_AR.xlsx");
        }
      })
      .catch(() => this.showErrorMessage("notif_process_fail"))
      .finally(() => {
        this.loadingIndicator.download = false;
        this.loadingIndicator.data = false;
      });
  }

  onClickBranch({ meta }: { meta: ResponseBranchWarehouse }): void {
    this.form.branchName = meta.name;
  }

  onChangeCustomer({ meta }: { meta: IOption<ContactData> }): void {
    this.form.customerFullName = meta.meta?.fullName || "";
  }

  onChangeCompanyBank({ meta }: { meta: DataResponseCompanyBank }): void {
    this.form.bankCompanyName = meta.bankName || "";
  }

  findData(type: "find" | "download"): void {
    this.loadingIndicator.data = true;
    this.searchForm.validate((valid) => {
      if (!valid) {
        this.loadingIndicator.data = false;
        return;
      }
      const params: string[] = [];
      const query: string[] = [];
      const queryDownload:string[] = [];
      params.push(this.$store.state.authStore.authData.companyName);
      if (this.form.branch) {
        query.push(`branchWarehouse.secureId~${this.form.branch}`);
        queryDownload.push(`invoiceARReceipt.branchWarehouse.secureId~${this.form.branch}`);
        params.push(this.form.branchName);
      } else params.push("ALL");

      if (this.form.arReceiptNumber) {
        query.push(`documentNumber~*${this.form.arReceiptNumber}*`);
        queryDownload.push(`invoiceARReceipt.documentNumber~*${this.form.arReceiptNumber}*`);
        params.push(`${this.form.arReceiptNumber}`);
      } else params.push("ALL");

      if (this.form.receiptDate.length > 0) {
        query.push(
          `receiptDate>=${moment(
            this.form.receiptDate[0]
          ).format()}_AND_receiptDate<=${moment(
            this.form.receiptDate[1]
          ).format()}`
        );
        queryDownload.push(
          `invoiceARReceipt.receiptDate>=${moment(
            this.form.receiptDate[0]
          ).format()}_AND_invoiceARReceipt.receiptDate<=${moment(
            this.form.receiptDate[1]
          ).format()}`
        );
      }

      if (this.form.customerName) {
        query.push(`customer.secureId~${this.form.customerName}`);
        queryDownload.push(`invoiceARReceipt.customer.secureId~${this.form.customerName}`);
        params.push(`${this.form.customerFullName.replaceAll(",", " ")}`);
      } else params.push("ALL");

      if (this.form.currency) {
        query.push(`priceCurrency.secureId~${this.form.currency}`);
        queryDownload.push(`invoiceARReceipt.priceCurrency.secureId~${this.form.currency}`);
      }

      if (this.form.receiptType) {
        query.push(`receiptType~${trimSpaceToUnderscore(this.form.receiptType)}`);
        queryDownload.push(`invoiceARReceipt.receiptType~${trimSpaceToUnderscore(this.form.receiptType)}`);
      }

      if (this.form.bankName) {
        query.push(`bankAccount.secureId~*${this.form.bankName}*`);
        queryDownload.push(`invoiceARReceipt.bankAccount.secureId~*${this.form.bankName}*`);
      }

      if (this.form.chequeNumber) {
        query.push(`chequeNumber~*${this.form.chequeNumber}*`);
        queryDownload.push(`invoiceARReceipt.chequeNumber~*${this.form.chequeNumber}*`);
      }
      
      this.query.search = query.join("_AND_");
      // this.query.params = params.join(',')
      if(type === "find") {
        this.getListArReceipt();
      } else {

        if (this.form.bankName) {
          params.push(`${this.form.bankCompanyName}`);
        } else {
          params.push("ALL");
        }

        if (this.form.chequeNumber) {
          params.push(`${this.form.chequeNumber}`);
        } else {
          params.push("ALL");
        }

        this.query.search = queryDownload.join("_AND_");
        this.query.params = params.join(",");

        this.handleDownload();
      }
    });
  }

  async getListArReceipt(): Promise<void> {
    try {
      this.loadingIndicator.data = true;
      const res = await this.getListArReceipt$(this.query);
      this.dtSource = res.data;
      this.dtReceiptAr = res;
    } catch (error) {
      this.showErrorMessage("notif_process_fail");
    } finally {
      this.loadingIndicator.data = false;
    }
  }

  getArReceiptNumber(firstInit = false): void {
    this.arReceiptNumberOption.fetching = true;
    const query: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
    };
    if (this.arReceiptNumberOption.search) {
      query.search = `documentNumber~*${this.arReceiptNumberOption.search}*`;
    }
    this.getListArReceipt$(query)
      .then((res) => {
        this.arReceiptNumberOption.data = res.data;
        if (firstInit) {
          this.arReceiptNumberOption.initialData = res.data;
        }
      })
      .catch(() => this.showErrorMessage("notif_process_fail"))
      .finally(() => (this.arReceiptNumberOption.fetching = false));
  }

  searchReceiptNumber(value: string): void {
    if (value) {
      this.arReceiptNumberOption.search = value;
      this.getArReceiptNumber();
    }
  }

  resetForm(): void {
    this.searchForm.resetFields();
  }

  createNew(): void {
    this.$router.push({ name: "accountreceivables.ar.receipt.create" });
  }

}
